@font-face {
  font-display: swap;
  font-family: 'DrukTextWide';
  src: local('Custom Font'), url('../assets/fonts/DrukTextWide/subset-DrukTextWide-Bold.woff2') format('woff2'), 
  url('../assets/fonts/DrukTextWide/subset-DrukTextWide-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  unicode-range: 'U+000-5FF';
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  src: local('Custom Font'), url('../assets/fonts/Inter/subset-InterUI-Bold.woff2') format('woff2'),
  url('../assets/fonts/Inter/subset-InterUI-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  unicode-range: 'U+000-5FF';
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  src: local('Custom Font'), url('../assets/fonts/Inter/subset-Inter-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/Inter/subset-Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  unicode-range: 'U+000-5FF';
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  src: local('Custom Font'), url('../assets/fonts/Inter/subset-InterUI-Medium.woff2') format('woff2'),
  url('../assets/fonts/Inter/subset-InterUI-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  unicode-range: 'U+000-5FF';
}

@font-face {
  font-display: swap;
  font-family: 'Inter';
  src: local('Custom Font'), url('../assets/fonts/Inter/subset-InterUI-Regular.woff2') format('woff2'),
  url('../assets/fonts/Inter/subset-InterUI-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  unicode-range: 'U+000-5FF';
}
